/******************************************************************************\
|                                                                              |
|                               publications.scss                              |
|                                                                              |
|******************************************************************************|
|                                                                              |
|        This defines various CSS styles used in this application.             |
|                                                                              |
|        Author(s): Abe Megahed                                                |
|                                                                              |
|        This file is subject to the terms and conditions defined in           |
|        'LICENSE.txt', which is part of this source code distribution.        |
|                                                                              |
|******************************************************************************|
|        Copyright (C) 2012-2020 Morgridge Institute for Research (MIR)        |
\******************************************************************************/

.publisher {
	font-size: 38px;
}

.journal {
	border-width: 3px;
	border-style: solid;
	padding: 25px;
}

.journal:not(:first-child) {
	border-left-width: 0;
}

.journal-wrapper {
	text-align: center;
}

.journal-source {
	font-size: 14px;
	letter-spacing: 2px;
	font-weight: 200;
}

.product {
	padding: 35px 0;
}

.title-short {
	display: none;
}

/*
 * summaries
 */

.summary-row {
	padding: 20px;
}

.summary-stat > span {
	display: block;
	font-size: 25px;
	font-weight: 200;
}

.summary-stat {
	font-size: 15px;
	text-align: center;
	font-weight: bold;
	color: #ccc;
}

.summary-type {
	font-size: 40px;
	color: #F7F7F7 !important;
}

.summary-type.journal {
	font-size: 25px;
}

/*
 * fetched items
 */

div[id*="-fetched"] > span {
	font-size:525px;
}

.fetched {
	border-bottom-width: 0;
}

.fetched > span {
	font-size: 50px;
}

/*
 * recent items
 */

.recent {
	border: 3px solid #E06E2D;
}

.recent-title {
	background-color: #E06E2D;
	height: 160px;
}

.recent-list {
	list-style: none;
}

.recent-list > li {
	padding: 10px 0;
	padding-left: 2em;
	text-indent: -2em;
	color: #555;
}

.recent-list a {
	color: #333;
	text-decoration: none;
}

/*
 * mobile styles
 */

@media(max-width: 600px) {

	.title-full {
		display: none;
	}

	.title-short {
		display: block;
	}

	.journal:not(:first-child) {
		border-left-width: 3px;
	}

	.journal:not(:last-child) {
		border-bottom-width: 0;
	}
}