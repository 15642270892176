/******************************************************************************\
|                                                                              |
|                                   main.scss                                  |
|                                                                              |
|******************************************************************************|
|                                                                              |
|        This defines various CSS styles used in this application.             |
|                                                                              |
|        Author(s): Abe Megahed                                                |
|                                                                              |
|        This file is subject to the terms and conditions defined in           |
|        'LICENSE.txt', which is part of this source code distribution.        |
|                                                                              |
|******************************************************************************|
|        Copyright (C) 2012-2020 Morgridge Institute for Research (MIR)        |
\******************************************************************************/

@import 'layout.scss';
@import 'navs.scss';
@import 'buttons.scss';
@import 'home.scss';
@import 'branding.scss';
@import 'publications.scss';
@import 'datasets.scss';
@import 'results.scss';