/******************************************************************************\
|                                                                              |
|                                   navs.scss                                  |
|                                                                              |
|******************************************************************************|
|                                                                              |
|        This defines various CSS styles used in this application.             |
|                                                                              |
|        Author(s): Abe Megahed                                                |
|                                                                              |
|        This file is subject to the terms and conditions defined in           |
|        'LICENSE.txt', which is part of this source code distribution.        |
|                                                                              |
|******************************************************************************|
|        Copyright (C) 2012-2020 Morgridge Institute for Research (MIR)        |
\******************************************************************************/

/*
 * nav pills
 */

.nav-pills {
	> li {

		a {
			color: #777;
			cursor: default;
		}

		&.active {
			> a, > a:hover, > a:focus {
				background-color: #777;
			}	
		}
	}
}

/*
 * links
 */

a {
	font-weight: 500;

	&:hover {
		text-decoration: none;
	}
}

/*
 * nav links
 */

.nav {
	> li {
		> a:hover, > a:focus {
			background-color: silver;
			color: white;
		}
	}
}
