/******************************************************************************\
|                                                                              |
|                                 results.scss                                 |
|                                                                              |
|******************************************************************************|
|                                                                              |
|        This defines various CSS styles used in this application.             |
|                                                                              |
|        Author(s): Abe Megahed                                                |
|                                                                              |
|        This file is subject to the terms and conditions defined in           |
|        'LICENSE.txt', which is part of this source code distribution.        |
|                                                                              |
|******************************************************************************|
|        Copyright (C) 2012-2020 Morgridge Institute for Research (MIR)        |
\******************************************************************************/

.results {

	/*
	 * results list items
	 */

	li {
		padding: 15px;

		.heading {
			font-size: 125%;
		}

		i {
			margin: 0 5px;
		}

		/*
		 * wells
		 */

		.well {
			margin-top: 20px;
			position: relative;

			em {
				font-weight: bold;
			}

			.badge {
				position: absolute;
				right: -10px;
				top: -10px;
				margin-left: -20px;
				border: 2px solid white;
				box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
			}
		}

		/*
		 * dividers
		 */

		&:not(:last-child) {
			border-bottom: 1px dashed lightgrey;
		}
	}
}

/*
 * region for showing search status
 */

.status.well {
	padding: 5px;
	margin: 20px 0;
	text-align: center;
}

/*
 * remove spinners from num results input
 */

.num-results input[type=number]::-webkit-inner-spin-button, 
.num-results input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}

/*
 * page controls
 */

.page-controls {
	width: 100%;
	text-align: center;

	label {
		width: 100%;
	}

	/*
	 * remove spinners from page controls inputs
	 */

	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
		-webkit-appearance: none; 
		margin: 0; 
	}

	.input-group {
		display: inline-table;
		width: 1px;

		.form-control {
			width: 4em;
		}

		.input-group-addon {
			padding: 5px;

			i {
				margin-right: 0;
			}

			button {
				width: 20px;
				text-align: center;
				padding: 0;
				border: none;
				outline: none;
				background: none;
			}
		}
	}
}

/*
 * mobile styles
 */

@media(max-width: 600px) {

	button.search-again i {
		margin-right: 0;
	}
}